input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
  }
  .NumberInputField{
    width: 80px;
    margin-left: 10PX;
    padding:5px 0;
    text-align: center;
    margin-right: 10PX;
    background: white;
    border:1px solid #ced4da;
  }
  
  .NumberInputButton{
      padding: 5px 10px;
      background: white;
      border:1px solid #ced4da;
  }