
.input-group {
    position: absolute;
    z-index: 1;
    
   }

  .spanCalendar{
    position: absolute;
    z-index: 1;
    right: 29px;
    margin-top: 40px

  }
  
  .fa-calendar{
    color: gray;
    
  }
  .react-date-picker{
    border: 1px solid #ced4da !important;
    display: block  !important;
    width: 100%  !important;
    height: calc(1.5em + 0.75rem + 2px) !important;
    padding: 0.375rem 0.75rem  !important;
    font-size: 1rem  !important;
    font-weight: 400  !important;
    line-height: 1.5  !important;
    color: #495057  !important;
    background-color: #fff  !important;
    background-clip: padding-box  !important;
    border: 1px solid #8e9092  !important;
    border-radius: 0.25rem  !important;
  
  }
  .react-date-picker__wrapper{
    border: #fff !important;

  }