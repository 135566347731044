@import "../../../../assets/css/common.scss";

.cafHeader {
  width: 100%;
  border-bottom: 1px solid $borderColor;

  h2 {
    font-size: $font20;
    color: $primaryColor;
    padding: 20px;
  }
}
.internal_caf {
  padding: 20px;
  width: 100%;
  background-color: $whiteColor;

  .fileuploader {
    .upload-file-name {
      margin-left: 0px !important;
    }
  }

  .upload-file-name {
    background-color: $whiteColor !important;
    border-radius: 5px;
    margin-left: -15px;
  }

  .chapterttl {
    font-size: $font18;
    color: $secondaryColor;
    font-weight: bold;
    margin-bottom: 25px;
  }

  .ActivitydownloadedFile {
    .col-lg-12 {
      padding-left: 0px;
    }
  }

  .uploadedFile {
    position: relative;
    border: 1px solid #ced4da;
    padding: 5px 35px 5px 10px;
    color: $secondaryColor;
    background-color: $whiteColor;
    border-radius: 3px;
    font-size: $font15;

    label {
      color: $textColor;
      position: absolute;
      top: -28px;
      left: 0px;
      font-size: $font14;
    }

    i {
      width: 20px;
      height: 20px;
      background-color: $primaryColor;
      border-radius: 50%;
      text-align: center;
      line-height: 20px;
      position: absolute;
      right: 15px;
      top: 50%;
      margin-top: -10px;
    }
  }

  .intermediery {
    border: 1px solid $fieldsetLegend;

    .intermediary-heading {
      background-color: $fieldsetLegend;
      color: $textColor;
      padding: 6px 12px;
    }

    .intermediary-body {
      padding: 15px;
    }
  }

  .smallSubTitle {
    font-size: $font16;
    font-weight: bold;
    color: $primaryColor;
  }

  .internalActivity {
    h4 {
      font-size: $font16;
      font-weight: bold;
      color: $secondaryColor;
    }
  }

  fieldset {
    padding: 15px;
    background-color: $whiteColor;

    .internalUserApproval {
      border-top: 1px solid $fieldsetLegend;
      padding: 15px 15px 0px 15px;
      width: 100%;
    }
  }

  .bankinfoInternal {
    .rdt_TableHeader {
      display: none;
    }
  }

  .internalro {
    .rdt_Table,
    .rdt_TableRow {
      background-color: $whiteColor !important;
    }
    .rdt_TableHeader:first-child {
      margin: 0px;
      padding: 0px;

      div:first-child {
        font-size: 16px;
        font-weight: bold;
        padding-left: 0px;
      }
    }
    .rdt_Table {
      border: 1px solid $borderColor;
      margin-bottom: 15px;
    }
    .rdt_TableRow {
      padding: 10px 0px;
    }
  }

  .internalErrorSection {
    background-color: #dbf3fd;
    border-radius: 10px;
    padding: 20px;

    h3 {
      font-size: $font18;
      margin-bottom: 20px;
      color: $primaryColor;
    }

    a {
      color: $secondaryColor;
      padding: 0px 0px 0px 15px;
      margin: 8px 0px 8px 0px;
      display: block;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .editableNotice {
    display: flex;
    align-items: center;
    background-color: $fieldsetLegend;
    padding: 10px;
    font-size: 12px;
    border-radius: 10px;
    flex-wrap: wrap;

    input,
    .react-date-picker {
      padding: 2px 5px;
      margin: 2px 3px;
      display: inline-flex;
      background-color: rgba(255, 255, 255, 0.2) !important;
      border-radius: 5px;
      border: 1px solid rgba(255, 255, 255, 0.5);

      &:focus {
        outline: none;
      }
    }
    .noticeName {
      width: 115px;
    }
    .noticeEntity {
      width: 125px;
    }
    .noticeDate {
      width: 90px;
    }
    .react-date-picker {
      border: 1px solid rgba(255, 255, 255, 0.5) !important;
      padding: 0px !important;
      height: 25px !important;

      input {
        font-size: 12px !important;
        border: none !important;
        height: 21px !important;
        margin: 0px !important;
        background-color: transparent !important;
      }

      .react-date-picker__button {
        svg {
          width: 14px;
          height: 14px;
        }
      }
    }

    .react-date-picker__inputGroup {
      position: relative;
    }

    .react-date-picker__inputGroup__input {
      height: auto;
    }
  }

  .signatureControl {
    .react-date-picker__wrapper {
      .react-date-picker__clear-button {
        display: none;
      }
    }
  }

  .rdt_TableBody {
    min-height: auto !important;
  }

  .smallerFont {
    font-size: smaller !important;
  }

  .inline-checkbox {
    .options {
      label {
        margin-right: 15px;
      }
    }
  }
}

.signedDatatable {
  margin-bottom: 15px;
  border-radius: 10px;

  .rdt_TableHeader {
    display: none;
  }

  .rdt_TableBody {
    min-height: auto;
  }
}

.InternalPDFexportCSS {
  .action-btn {
    display: none !important;
  }
  .cafHeader {
    display: none !important;
  }
  fieldset legend {
    margin-top: -10px !important;
  }
  .userlist,
  .internal_caf {
    padding: 0px !important;
    border: none !important;
  }
  .rdt_TableBody {
    height: auto !important;
  }
  .groupCompaniesBlock {
    padding: 8px 0px !important;
  }
  .fileuploader input {
    display: none !important;
  }
  .css-g1d714-ValueContainer {
    padding: 3px 8px;
  }
}
