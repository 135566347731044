@import "./../../../assets/css/common.scss";

.office-button {
  background-repeat: no-repeat;
  background-position: right center;
  background-image: url(../../../assets/images/icon-filter.png);
  display: inline-block;
  padding: 8px 16px;
}
.office-dropdown-content {
  cursor: auto;
  color: #000;
  background-color: #fff;
  display: none;
  position: absolute;
  min-width: 200px;
  margin: 0;
  padding: 0;
  z-index: 1;
  right: -10px;
}
.office-dropdown-hover {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.office-dropdown-hover:hover .office-dropdown-content {
  display: block;
}
.office-dropdown-hover:first-child,
.office-dropdown-click:hover {
  background-color: #fff;
  color: #172983;
}
.office-dropdown-hover:hover > .office-button:first-child,
.office-dropdown-click:hover > .office-button:first-child {
  color: #172983;
}
#checkboxes,#checkboxesstatus {
  padding: 10px 0;
  border: 1px solid #e1e1e1;
  box-shadow: 0 5px 4px 0 rgba(218, 221, 222, 0.35);
  background-color: white;

  label {
    display: flex;
    align-items: center;
    padding: 5px 4px 5px 15px;
    font-size: 14px;
    margin-bottom: 5px;
    cursor: pointer;
    user-select: none;

    &:last-child {
      margin-bottom: 0px;
    }

    &:hover {
      background-color: #fbfbfb;
    }
  }

}

#filterName {
  font-size: 14px;
  color: black;
  font-weight: normal;
}

.office-dropdown-hover {
  background: none !important;

  .office-button:hover {
    color: $primaryColor;
  }
  :hover {
    color: $primaryColor !important;
  }
  .office-button svg {
    color: #cb0303;
  }
  .office-dropdown-content {
    background-color: white;
    #checkboxes,#checkboxesstatus {
      label {
        #filterName {
          margin-left: 10px;
        }
      }
    }
  }
}
