@import "./../../../assets/css/common.scss";
.addUserflow {
  background-color: $whiteColor;
  border-radius: 5px;
  padding: 25px 30px;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.1);

  label {
    font-size: $font14;
    color: $textColor;
    margin-bottom: 5px;
  }
  .input_field, .searchWrapper {
    border: 1px solid $borderColor;
    background-color: $whiteColor;
    padding: 5px 15px;
    font-size: $font14;
    min-height: 40px;
    width: 100%;
    border-radius: 2px;
  }
  .react-tel-input {
    .form-control {
      padding-left: 48px;      
      @extend .input_field
    }
  }
}
.heading2 {
  font-size: $font18;
  font-weight: $fontBold;
  color: $primaryColor;
}

.autocomplete {
  .wrapper {
    border-radius: 2px;
    height: 40px;
    font-size: $font14;

    &:focus-within {
      box-shadow: none !important;
    }

    .frjscc {
      min-height: 40px;
    }

    .sc-bwzfxh,
    &:last-child {
      position: absolute;
      z-index: 9999;
      background-color: #fff !important;
    }

    .search-icon {
      margin: 0px;
      position: absolute;
      right: 10px;
      top: 10px;
    }

    .clear-icon {
      margin: 0px;
      position: absolute;
      right: 40px;
      top: 8px;
    }
  }
}
.addUserflow{
    .css-yk16xz-control{
      background: #f8f8f8 !important;
  }
 .input_field{
  background:#f8f8f8 !important;
}
}
/* Overriding element hover */
.optionContainer li:hover,
.optionContainer .highlight {
  background: #172983;
  color: #fff;
}


.datatableSearch {
  position: absolute;
  right: 29px;
  z-index: 1;

  input {
  border: 1px solid $borderColor;
  background-color: $whiteColor;
  padding: 5px 15px;
  font-size: $font14;
  height: 40px;
  border-radius: 2px; 
  width: 280px;
  
  }
  
}