// /*===========
//  COMMON VARIABLE CSS
//  ===========*/

 /* CSS style for colors */
 $primaryColor:#172983;
 $secondaryColor:#B00335;
 $textColor: #333333;
 $successColor: #18AE15;
 $errorColor: #CB0303;
 $whiteColor:#ffffff;
 $borderColor:#EEEEEE;
 $inputBg:#f5f5f5;

 $fieldsetLegend: #9bdefb;
 $highlightedBtn: #23B7E5;
 /* CSS style for fonts */
 //$font:'DaxPro',sans-serif!important;
 $font: 'Poppins', sans-serif!important;

 /* CSS style for font weight */
 $fontLight: 300;
 $fontRegular: normal; 
 $fontMedium: 500;
 $fontBold: bold;

 /* CSS style for font size */
 $font14: 14px;
 $font15: 15px;
 $font16: 16px;
 $font18: 18px;
 $font20: 20px;
 $font24: 24px;
 $font30: 30px;
 $font38: 38px;
 $font50: 50px;

 /* CSS style for Utilities */