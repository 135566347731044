.printPDFUBO {
  .eventHeader {
    border-bottom: none !important;
  }

  .accordion {
    .rounded-lg {
      margin-bottom: 0px !important;
    }
    .card-header {
      background-color: #eee !important;
      box-shadow: none !important;
    }
  }

  .pagetitle {
    font-size: 18px !important;
    font-weight: bold;
    color: #172983 !important;
    border-bottom: 2px solid #172983 !important;
    padding-bottom: 10px !important;
  }

  label,
  input,
  p {
    font-size: 14px;
    font-weight: normal;
  }

  .rdt_TableHeader {
    div {
      font-size: 14px;
      font-weight: normal;
    }
  }

  .sub-chapter-title,
  .pdf-title,
  .chapter-title {
    margin-top: 20px;
    font-size: 14px !important;
    border-bottom: 2px solid #b00335 !important;
    display: block;
    padding-bottom: 8px;
    color: #b00335;
  }

 

  .obligationComapnygrid {
    input {
      display: none;
    }
    .action-btn {
      display: none!important;
    }    
  }
  .rdt_TableBody { min-height: 120px;}
  .rdt_Pagination { display: none!important; }   
  .react-date-picker__clear-button {display: none;}

  .familyIncomeList {
    margin-top: 20px;
    .rdt_TableHeader {
      display: none;
    }
  }
  
}
#pdfCreation{
  .css-yk16xz-control{
    border-radius:0!important;
  }
  .css-g1d714-ValueContainer{
    padding: 3px 10px
  }
 .css-1uccc91-singleValue{
  padding-bottom: 20px!important;
  
 }
  .css-1hb7zxy-IndicatorsContainer{
    display: none;
  }
  .css-tlfecz-indicatorContainer{
    display: none;
  }
  #name{
    border-radius: 0px!important;   
  }
  #pepEmail{
    border-radius: 0px!important;    
  }
  #uboEmail{
    border-radius: 0px!important;    
  }
  #place{
    border-radius: 0px!important;
   }
}

.PDFexportCSS {
    .files {
      .col-xl-12 {
        display: none!important;
      }
    }

    .upload-file-name {
      height: 35px;
      .fileDownload {
        display: none !important;
      }
    }
    .fileDownload, .add-button, .action-btn, .Edit-Btn {
      display: none;
    }

    .react-date-picker__wrapper {
      button {
        display: none !important;
      }
    }

    .familyincomePDf {
      .action-btn {display: none!important; }
    }
    
}

.countrySelection {
  .css-1uccc91-singleValue {
    position: relative!important;
    transform: none;
  }
}
.upload-file-name  {
  .FileNameDisplay {
    overflow: visible;
    text-overflow: unset!important;
    white-space: unset!important;
    word-break: break-all;
  }
}